import React, { useState, useEffect } from "react";
import "./Personalinformation.scss";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { useFormik } from "formik";
// import * as Yup from "yup";
import { Country, State, City } from "country-state-city";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

import { personalInfoSchema } from "../../../validations/validation";
import {
  makeEncryptionData,
  makeDecryptionData,
} from "../../../services/encryptionDecription.service";
import { fetchData, SubmitData } from "../../../services/apiService";
import useAlertUtils from "../../../utils/common.utils";
// import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateTab } from "../../../redux/slices/tab.slices";

const Personalinformation = (props) => {
  const [imgUrl, setImgUrl] = useState(null);
  // const [loader, setLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  // const [someError, setSomeError] = useState(false);
  const [stateList, setStateList] = useState([]);
  const dispatch = useDispatch();

  const [cityList, setCityList] = useState([]);
  const [userInfoEmail, setUserInfoEmail] = useState("");
  const [checkbox, setCheckBox] = useState({
    check: "",
  });
  const [checkbox2, setCheckBox2] = useState({
    check2: "",
  });
  // const [serverError, setServerError] = useState("");
  // const navigate = useNavigate();
  const [formikReady, setFormikReady] = useState(false);
  // const tabState = useSelector((state) => state.tab.value);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    weight: "",
    height: "",
    heightInInch: "",
    email: userInfoEmail,
    address1: "",
    address2: "",
    country: "US",
    city: "",
    state: "",
    zipCode: "",
    mobileNumber: "",
    homeNumber: "",
    workNumber: "",
    prfrTime: [],
    prfrContact: [],
    gender: "",
    dob: dayjs(new Date()),
    // file : '',
    isCheck: false,
  });

  const { alertSuccess, alertError } = useAlertUtils();

  const formik = useFormik({
    initialValues: data,
    validationSchema: personalInfoSchema,

    onSubmit: async (values, { resetForm }) => {
      values.prfrTime = checkbox.check;
      values.prfrContact = checkbox2.check2;
      values.file = imgUrl;
      values.dob = dayjs(values.dob).format("MM/DD/YYYY");

      const country = Country.getCountryByCode(values.country);
      // const state = State.getStateByCodeAndCountry(
      //   values.state,
      //   values.country
      // );

      const formValues = {
        ...values,
        email: userInfoEmail,
        countryCode: values.country,
        stateCode: values.state,
        country: country.name,
        state: values.state,
      };

      const encryptedData = makeEncryptionData(formValues);

      if (encryptedData) {
        const response = await SubmitData(
          "/api/intakeForms/pearsonalInformation",
          encryptedData
        );
        if (response.success) {
          alertSuccess(response.message);
          resetForm();
          dispatch(updateTab("2"));
        } else {
          alertError(response.message);
        }
      }
    },
  });

  const handleStates = (event) => {
    const stateCode = event.target.value;
    const cityListData = City.getCitiesOfState(
      formik.values.country,
      stateCode
    );
    setCityList(cityListData);
    formik.setFieldValue("state", stateCode);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.tabVal == "1") {
      if (formik.initialValues.email == "") {
        setPageLoader(true);

        const stateListData = State.getStatesOfCountry("US");
        setStateList(stateListData);
        setFormikReady(true);
      }
    }
  }, []);

  useEffect(() => {
    const info = async () => {
      const infoData = await fetchData("/api/intakeForms/pearsonalInformation");
      if (infoData.data) {
        const decryptedData = makeDecryptionData(infoData.data);

        formik.setFieldValue("firstName", decryptedData.firstName);
        formik.setFieldValue("lastName", decryptedData.lastName);
        formik.setFieldValue("weight", decryptedData.weight);
        formik.setFieldValue("height", decryptedData.height);
        formik.setFieldValue("heightInInch", decryptedData.heightInInch);
        formik.setFieldValue("email", decryptedData.email);
        formik.setFieldValue("address1", decryptedData.address1);
        formik.setFieldValue("address2", decryptedData.address2);
        formik.setFieldValue("city", decryptedData.city);
        formik.setFieldValue("state", decryptedData.state);
        formik.setFieldValue("zipCode", decryptedData.zipCode);
        formik.setFieldValue("gender", decryptedData.gender);
        formik.setFieldValue("mobileNumber", decryptedData.mobileNumber);
        formik.setFieldValue("homeNumber", decryptedData.homeNumber);
        formik.setFieldValue("workNumber", decryptedData.workNumber);
        formik.setFieldValue("dob", dayjs(decryptedData.dob));
      }
    };
    if (formikReady) {
      info();
    }
  }, [formikReady]);

  return (
    <>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <fieldset>
          <div className="form_title">Personal Information</div>
          <div className="form_field_wrapper">
            <label>
              Legal First Name<span className="req">*</span>
            </label>
            <input
              type="text"
              placeholder="Legal First Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              className="form-control inp_text"
              id="firstName"
            />
            {/* If validation is not passed show errors */}
            <p className="error">
              {formik.errors.firstName &&
                formik.touched.firstName &&
                formik.errors.firstName}
            </p>
          </div>
          <div className="form_field_wrapper">
            <label>
              Legal Last Name<span className="req">*</span>
            </label>
            <input
              type="text"
              placeholder="Legal Last Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              className="form-control inp_text"
              id="lastName"
            />
            {/* If validation is not passed show errors */}
            <p className="error">
              {formik.errors.lastName &&
                formik.touched.lastName &&
                formik.errors.lastName}
            </p>
          </div>
          <div className="form_field_wrapper">
            <label>
              Gender<span className="req">*</span>
            </label>
            <select
              name="gender"
              id="gender"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control"
            >
              <option
                value=" "
                selected={
                  formik.values?.gender?.length != 0
                    ? formik.values.gender == " "
                      ? "checked"
                      : ""
                    : ""
                }
              >
                Select
              </option>
              <option
                value="male"
                selected={
                  formik.values?.gender?.length != 0
                    ? formik.values.gender == "male"
                      ? "checked"
                      : ""
                    : ""
                }
              >
                Male
              </option>
              <option
                value="female"
                selected={
                  formik.values?.gender?.length != 0
                    ? formik.values.gender == "female"
                      ? "checked"
                      : ""
                    : ""
                }
              >
                Female
              </option>
            </select>
            {/* If validation is not passed show errors */}
            <p className="error">
              {formik.errors.gender &&
                formik.touched.gender &&
                formik.errors.gender}
            </p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form_field_wrapper date_picker">
                <label>
                  Date Of Birth<span className="req">*</span>
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={[
                      "DatePicker",
                      "MobileDatePicker",
                      "DesktopDatePicker",
                      "StaticDatePicker",
                    ]}
                  >
                    <DemoItem>
                      <DesktopDatePicker
                        maxDate={dayjs(new Date())}
                        onChange={(value) =>
                          formik.setFieldValue("dob", value, true)
                        }
                        value={formik?.values?.dob}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
                <p className="error">
                  {formik.errors.dob && formik.touched.dob && formik.errors.dob}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form_field_wrapper">
                <label>
                  Weight (lbs)<span className="req">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Weight"
                  maxLength={3}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.weight}
                  className="form-control inp_text"
                  id="weight"
                />
                {/* If validation is not passed show errors */}
                <p className="error">
                  {formik.errors.weight &&
                    formik.touched.weight &&
                    formik.errors.weight}
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form_field_wrapper">
                <label>
                  Height (Ft)<span className="req">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Feet"
                  onChange={formik.handleChange}
                  maxLength="2"
                  onBlur={formik.handleBlur}
                  value={formik.values.height}
                  className="form-control inp_text"
                  id="height"
                />
                <p className="error">
                  {(formik.errors.height &&
                    formik.touched.height &&
                    formik.errors.height) ||
                    (formik.errors.heightInInch &&
                      formik.touched.heightInInch &&
                      formik.errors.heightInInch)}
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form_field_wrapper">
                <label>
                  Height (In)<span className="req">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Inches"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength="2"
                  value={formik.values.heightInInch}
                  className="form-control inp_text"
                  id="heightInInch"
                />
              </div>
            </div>
          </div>

          <div className="form_field_wrapper">
            {/* <label>Email<span className="req">*</span></label> */}
            <input
              type="hidden"
              placeholder="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className="form-control inp_text"
              id="email"
            />

            {/* {serverError == "" ? "" : <p className="error">{serverError}</p>} */}
          </div>
        </fieldset>

        <fieldset>
          <div className="form_title">Address</div>
          <div className="form_field_wrapper">
            <label>
              Address 1<span className="req">*</span>
            </label>
            <input
              type="text"
              placeholder="Address 1"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address1}
              className="form-control inp_text"
              id="address1"
            />
            {/* If validation is not passed show errors */}
            <p className="error">
              {formik.errors.address1 &&
                formik.touched.address1 &&
                formik.errors.address1}
            </p>
          </div>

          <div className="form_field_wrapper">
            <label>
              Address 2<span className="req"></span>
            </label>
            <input
              type="text"
              placeholder="Apt, Suite Number Optional"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address2}
              className="form-control inp_text"
              id="address2"
            />
            {/* If validation is not passed show errors */}
            <p className="error">
              {formik.errors.address2 &&
                formik.touched.address2 &&
                formik.errors.address2}
            </p>
          </div>
          <div className="row">
            <div className="col-md-4 d-none">
              <div className="form_field_wrapper">
                {/* <label>Country<span className="req">*</span></label> */}
                <input type="hidden" name="country" id="country" value="US" />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form_field_wrapper">
                <label>
                  State<span className="req">*</span>
                </label>
                <select
                  name="state"
                  id="state"
                  value={formik.values.state}
                  onChange={handleStates}
                  className={`form-control ${
                    formik.touched.state && formik.errors.state
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Select State</option>
                  {stateList.map((state) => (
                    <option key={state.name} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {formik.touched.state && formik.errors.state ? (
                  <div className="invalid-feedback">{formik.errors.state}</div>
                ) : null}
              </div>
            </div>

            <div className="col-md-4">
              <div className="form_field_wrapper">
                <label>
                  City<span className="req">*</span>
                </label>
                <input
                  type="text"
                  placeholder="City"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  className="form-control inp_text"
                  id="city"
                />

                <p className="error">
                  {formik.errors.city &&
                    formik.touched.city &&
                    formik.errors.city}
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form_field_wrapper">
                <label>
                  Zip Code<span className="req">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Zip code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.zipCode}
                  className="form-control inp_text"
                  id="zipCode"
                />
                {/* If validation is not passed show errors */}
                <p className="error">
                  {formik.errors.zipCode &&
                    formik.touched.zipCode &&
                    formik.errors.zipCode}
                </p>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <div className="form_title">Phone Numbers</div>
          <div className="row">
            <div className="col-md-4">
              <div className="form_field_wrapper">
                <label>
                  Mobile Phone<span className="req">*</span>
                </label>

                {/* <PhoneInput
      defaultCountry="US"
      value={formik.values.mobileNumber}
      onChange={value => formik.setFieldValue('mobileNumber', value)}
      onBlur={() => formik.handleBlur('mobileNumber')}
      countryCallingCodeEditable={false}
      className="international-phone-container"
    /> */}
                <PhoneInput
                  country={"us"}
                  defaultCountry="us"
                  countryCodeEditable={false}
                  id="mobileNumber"
                  className="international-phone-container"
                  value={formik.values.mobileNumber}
                  onBlur={formik.handleBlur("mobileNumber")}
                  onChange={(value) =>
                    formik.setFieldValue("mobileNumber", value)
                  }
                />
                {/* If validation is not passed show errors */}
                <p className="error">
                  {formik.errors.mobileNumber &&
                    formik.touched.mobileNumber &&
                    formik.errors.mobileNumber}
                </p>
              </div>
            </div>
          </div>
        </fieldset>

        <hr></hr>
        <div className="primary_btn">
          <button type="submit" className="btn btn-secondary">
            NEXT
          </button>
        </div>
      </form>
    </>
  );
};

export default Personalinformation;
