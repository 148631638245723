import React from "react";
// import { useState } from 'react'
import logoimg from "../../assets/img/logo_login.png";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

import PreLoginHeader from "../../components/PreLoginHeader/PreLoginHeader";
import Footer from "../../components/Footer/Footer";
import { recoveryPasswordSchema } from "../../validations/validation";
import { makeEncryptionData } from "../../services/encryptionDecription.service";
import { SubmitData } from "../../services/apiService";
import useAlertUtils from "../../utils/common.utils";

function RecoverPassword() {
  const { alertSuccess, alertError } = useAlertUtils();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: recoveryPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      const encryptedData = makeEncryptionData(values);

      if (encryptedData) {
        const response = await SubmitData(
          "/api/users/recovery-password",
          encryptedData
        );
        if (response?.success) {
          alertSuccess(response?.message);
          resetForm();
        } else {
          alertError(response?.message);
        }
      }
    },
  });

  return (
    <>
      <PreLoginHeader></PreLoginHeader>

      <div className="login">
        <div className="login_inner">
          <div className="row">
            <div className="left_part part col-md-6">
              <div className="leftpart_inner">
                <div className="logo">
                  <img src={logoimg} alt="Logo Img" />
                </div>
              </div>
            </div>
            <div className="right_part part col-md-6">
              <div className="rightpart_inner">
                <div className="login_form">
                  <div className="login_form_inner">
                    <form noValidate onSubmit={formik?.handleSubmit}>
                      <h1 className="login_title">Forgot Password?</h1>
                      <div className="login_desc">
                        Don't Worry! Please enter the email <br /> address
                        associated with your account.
                      </div>
                      <div className="form_field_wrapper">
                        <label>Email</label>
                        <input
                          type="email"
                          name="email"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                          value={formik?.values?.email}
                          placeholder="Enter Email"
                          className="form-control inp_text"
                          id="email"
                        />
                        {/* If validation is not passed show errors */}
                        {formik?.errors?.email && formik?.touched?.email && (
                          <p className="error">{formik?.errors?.email}</p>
                        )}
                      </div>

                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-primary recoverpass"
                      >
                        Submit
                      </button>

                      <div className="form_field_wrapper backtologin_block">
                        <Link className="linkto backtologin" to="/">
                          Already have an account?{" "}
                          <span className="login_txt">Login</span>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}

export default RecoverPassword;
