import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import PreLoginHeader from "../../components/PreLoginHeader/PreLoginHeader";
import consultimg1 from "../../assets/img/consultimg1.svg";
import consultimg2 from "../../assets/img/consultimg2.svg";
import consultimg3 from "../../assets/img/consultimg3.svg";
import "./Register.scss";

import { useNavigate, Link, useLocation } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { SignUpSchema } from "../../validations/validation";
import { SubmitData } from "../../services/apiService";
import {
  decryptedData,
  makeEncryptionData,
} from "../../services/encryptionDecription.service";
import useAlertUtils from "../../utils/common.utils";

function Register() {
  const [passwordType, setPasswordType] = useState("password");
  const { alertSuccess, alertError } = useAlertUtils();
  const navigate = useNavigate();

  const location = useLocation();

  // Create an instance of URLSearchParams to parse the query string
  const queryParams = new URLSearchParams(location.search);

  // Retrieve the parameters
  const procedure = queryParams.get("procedure");
  const productId = queryParams.get("productId");

  useEffect(() => {
    if (procedure && productId) {
      localStorage.setItem("procedure", procedure);
      localStorage.setItem("productId", productId);
    }

    const token = localStorage.getItem("token");
    let role = localStorage.getItem("uR"); //user Role

    if (token && role && procedure && productId) {
      role = decryptedData(role);
      if (role === "patient") {
        navigate("/app/medical-history");
      }
    } else {
      if (location.pathname && location.search) {
        localStorage.setItem("productURL", location.pathname + location.search);
      }
    }
  }, [procedure, productId, navigate]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      checkTick: null,
    },
    validationSchema: SignUpSchema,
    onSubmit: async (values) => {
      const encryptedData = makeEncryptionData(values);
      encryptedData.role = 2;
      encryptedData.agreeTerms = values?.checkTick ? 1 : 0;

      delete encryptedData.checkTick;

      if (encryptedData) {
        const response = await SubmitData("/api/users/signup", encryptedData);
        if (response?.success) {
          alertSuccess(
            "A verification email has been sent to your registered email"
          );
          navigate("/");
        } else {
          alertError(response?.message);
        }
      }
    },
  });
  return (
    <>
      <PreLoginHeader></PreLoginHeader>
      <div className="main_content consultation_homepage">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-7">
              <div className="consult_login">
                <div className="testimonial_inner">
                  <form noValidate onSubmit={formik?.handleSubmit}>
                    <h1 className="login_title">Create An Account</h1>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form_field_wrapper">
                          <label>First Name</label>
                          <input
                            type="text"
                            name="firstName"
                            onChange={formik?.handleChange}
                            onBlur={formik?.handleBlur}
                            value={formik?.values?.firstName}
                            placeholder="Enter First Name"
                            className="form-control inp_text"
                            id="firstName"
                          />
                          {/* If validation is not passed show errors */}
                          <p className="error">
                            {formik?.errors?.firstName &&
                              formik?.touched?.firstName &&
                              formik?.errors?.firstName}
                          </p>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form_field_wrapper">
                          <label>Last Name</label>
                          <input
                            type="text"
                            name="lastName"
                            onChange={formik?.handleChange}
                            onBlur={formik?.handleBlur}
                            value={formik?.values?.lastName}
                            placeholder="Enter Last Name"
                            className="form-control inp_text"
                            id="lastName"
                          />
                          {/* If validation is not passed show errors */}
                          <p className="error">
                            {formik?.errors?.lastName &&
                              formik?.touched?.lastName &&
                              formik?.errors?.lastName}
                          </p>
                        </div>

                        {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                      </div>
                    </div>

                    <div className="form_field_wrapper">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        value={formik?.values?.email}
                        placeholder="Enter Email"
                        className="form-control inp_text"
                        id="email"
                      />
                      {/* If validation is not passed show errors */}
                      <p className="error">
                        {formik?.errors?.email &&
                          formik?.touched?.email &&
                          formik?.errors?.email}
                      </p>
                    </div>

                    {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}

                    <div className="form_field_wrapper">
                      <label>Password</label>
                      <div className="password-input-wrapper">
                        <input
                          type={passwordType}
                          name="password"
                          onChange={formik?.handleChange}
                          onBlur={formik?.handleBlur}
                          value={formik?.values?.password}
                          placeholder="Enter Password"
                          className="form-control mb-2"
                        />
                        {passwordType === "text" ? (
                          <VisibilityIcon
                            onClick={() => {
                              setPasswordType("password");
                            }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={() => {
                              setPasswordType("text");
                            }}
                          />
                        )}
                      </div>

                      {/* If validation is not passed show errors */}
                      <p className="error">
                        {formik?.errors?.password &&
                          formik?.touched?.password &&
                          formik?.errors?.password}
                      </p>
                    </div>

                    <label>
                      <input
                        type="checkbox"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        value={formik?.values?.checkTick}
                        id="checkTick"
                        name="checkTick"
                      />
                      <span>
                        {" "}
                        I agree to the{" "}
                        <Link to="/terms-and-conditions">
                          Terms And Conditions
                        </Link>
                        , <Link to="/privacy-policy">Privacy Policy</Link> and{" "}
                        <Link to="/consent">Telehealth Consent</Link>.
                      </span>
                    </label>
                    <p className="error">
                      {formik?.errors?.checkTick &&
                        formik?.touched?.checkTick &&
                        formik?.errors?.checkTick}
                    </p>
                    {/* Click on submit button to submit the form */}
                    <button
                      type="submit"
                      className="btn btn-primary signup_btn"
                    >
                      Sign Up
                    </button>
                    <div className="form_field_wrapper backtologin_block">
                      <Link className="linkto backtologin" to="/">
                        Already Have An Account?{" "}
                        <span className="login_txt">Login</span>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="testimonial">
                <div className="testimonial_inner">
                  <div className="consultation_block">
                    <h2 className="main_title">
                      Start Your Free Consultation With Our Platform
                    </h2>
                    <div className="row">
                      <div className="col-12  consult_item">
                        <div className="consult_item_inner">
                          <img src={consultimg1} alt="Consult Image1" />
                          <div className="consult_desc">
                            <h3 className="title">Care</h3>
                            <div className="desc">
                              Ongoing support from our care team
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12  consult_item">
                        <div className="consult_item_inner">
                          <img src={consultimg2} alt="Consult Image2" />
                          <div className="consult_desc">
                            <h3 className="title">Free Shipping</h3>
                            <div className="desc">
                              Delivered to your doorstep
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 consult_item">
                        <div className="consult_item_inner">
                          <img src={consultimg3} alt="Consult Image3" />
                          <div className="consult_desc">
                            <h3 className="title">Medical Evaluation</h3>
                            <div className="desc">
                              Medical assesments and prescriptions provided by
                              licensed prescriber in the U.S.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Register;
