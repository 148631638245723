import React from "react";
// import { useState } from 'react'
import logoimg from "../../assets/img/logo_login.png";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";

import PreLoginHeader from "../../components/PreLoginHeader/PreLoginHeader";
import Footer from "../../components/Footer/Footer";

import { makeEncryptionData } from "../../services/encryptionDecription.service";
import { SubmitData } from "../../services/apiService";
import useAlertUtils, { setCookie } from "../../utils/common.utils";
import { changePasswordSchema } from "../../validations/validation";

function NewPassword() {
  const { alertSuccess, alertError } = useAlertUtils();
  const { token } = useParams();
  setCookie("token", token, 2);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: async (values) => {
      const encryptedData = makeEncryptionData(values);

      if (encryptedData) {
        const response = await SubmitData(
          "/api/users/change-password",
          encryptedData
        );

        if (response?.success) {
          alertSuccess(response?.message);
          navigate("/");
        } else {
          alertError(response?.message);
        }
      }
    },
  });

  return (
    <>
      <PreLoginHeader></PreLoginHeader>

      <div className="login">
        <div className="login_inner">
          <div className="row">
            <div className="left_part part col-md-6">
              <div className="leftpart_inner">
                <div className="logo Img">
                  <img src={logoimg} alt="logo" />
                </div>
              </div>
            </div>
            <div className="right_part part col-md-6">
              <div className="rightpart_inner">
                <div className="login_form">
                  <div className="login_form_inner">
                    <form noValidate onSubmit={formik.handleSubmit}>
                      <h1 className="login_title">Change Password</h1>
                      <div className="form_field_wrapper">
                        <label>New Password</label>
                        <input
                          type="password"
                          name="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          placeholder="Enter New Password"
                          className="form-control inp_text"
                          id="password"
                        />
                        {/* If validation is not passed show errors */}
                        {formik.errors.password && formik.touched.password && (
                          <p className="error">{formik.errors.password}</p>
                        )}
                      </div>

                      <div className="form_field_wrapper">
                        <label>Confirm New Password</label>
                        <input
                          type="password"
                          name="newPassword"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.newPassword}
                          placeholder="Confirm New Password"
                          className="form-control inp_text"
                          id="newPassword"
                        />
                        {/* If validation is not passed show errors */}
                        {formik.errors.newPassword &&
                          formik.touched.newPassword && (
                            <p className="error">{formik.errors.newPassword}</p>
                          )}
                      </div>

                      <button
                        variant="primary"
                        type="submit"
                        className="btn btn-primary recoverpass"
                      >
                        Submit
                      </button>

                      <div className="form_field_wrapper backtologin_block">
                        <Link className="linkto backtologin" to="/">
                          Already have an account?{" "}
                          <span className="login_txt">Login</span>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}

export default NewPassword;
