import React from "react";
// import {useEffect ,useState } from "react";
import { useFormik } from "formik";
// import * as Yup from "yup";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../ChangePassword/ChangePassword.scss";

import { useNavigate } from "react-router-dom";
import { changeOldPasswordSchema } from "../../validations/validation";
import { SubmitData } from "../../services/apiService";
import { makeEncryptionData } from "../../services/encryptionDecription.service";
import useAlertUtils from "../../utils/common.utils";

const ChangePassword = () => {
  const navigate = useNavigate();

  const data = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  // const [data, setData] = useState({
  //   oldPassword: "",
  //   newPassword: "",
  //   confirmNewPassword: "",
  // });
  const { alertSuccess, alertError } = useAlertUtils();
  const formik = useFormik({
    initialValues: data,
    validationSchema: changeOldPasswordSchema,

    onSubmit: async (values, { resetForm }) => {
      const encryptedData = makeEncryptionData(values);

      if (encryptedData) {
        const response = await SubmitData(
          "/api/users/password/changeOld/",
          encryptedData
        );
        if (response?.success) {
          alertSuccess(response?.message);
          //  navigate("/");
          resetForm();
        } else {
          alertError(response?.message);
        }
      }
    },
  });

  return (
    <>
      <Header></Header>

      <div className="main_content">
        <div className="container container_change_password">
          <div className="main_block change_password">
            <form onSubmit={formik?.handleSubmit} encType="multipart/form-data">
              <fieldset>
                <div className="main_title">Change Password</div>
                <div className="form_field_wrapper">
                  <label>
                    Old Password<span className="req">*</span>
                  </label>
                  <input
                    type="password"
                    placeholder="Old Password"
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values?.oldPassword}
                    className="form-control inp_text"
                    id="oldPassword"
                  />
                  {/* If validation is not passed show errors */}
                  <p className="error">
                    {formik?.errors?.oldPassword &&
                      formik?.touched?.oldPassword &&
                      formik?.errors?.oldPassword}
                  </p>
                </div>
                <div className="form_field_wrapper">
                  <label>
                    New Password<span className="req">*</span>
                  </label>
                  <input
                    type="password"
                    placeholder="New Password"
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values?.newPassword}
                    className="form-control inp_text"
                    id="newPassword"
                  />
                  {/* If validation is not passed show errors */}
                  <p className="error">
                    {formik?.errors?.newPassword &&
                      formik?.touched?.newPassword &&
                      formik?.errors?.newPassword}
                  </p>
                </div>
                <div className="form_field_wrapper">
                  <label>
                    Confirm Password<span className="req">*</span>
                  </label>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values?.confirmNewPassword}
                    className="form-control inp_text"
                    id="confirmNewPassword"
                  />
                  {/* If validation is not passed show errors */}
                  <p className="error">
                    {formik?.errors?.confirmNewPassword &&
                      formik?.touched?.confirmNewPassword &&
                      formik?.errors?.confirmNewPassword}
                  </p>
                </div>

                <div className="primary_btn">
                  <input
                    className="btn btn-secondary"
                    type="button"
                    value="previous"
                    onClick={() => navigate("/lab-results")}
                  ></input>
                  <button
                    type="submit"
                    className="btn btn-secondary bg-danger text-white"
                  >
                    Submit
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default ChangePassword;
