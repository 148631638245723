import React from "react";
// import { useEffect, useState } from 'react'
// import { useParams, useNavigate } from 'react-router-dom'
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import hipaa from "../../assets/img/hipaa.svg";

import "react-lazy-load-image-component/src/effects/blur.css";

import "./Confirmation.scss";

const Confirmation = () => {
  // const [pageLoader, setPageLoader] = useState(false)

  return (
    <>
      <Header></Header>
      <div className="main_content idverify">
        <div className="container">
          <div className="main_block">
            <div className="privacy_policy">
              <div className="title_wrapper text-center">
                <div className="title">
                  Congrats, your order is pending to be approved by the licensed
                  medical provider.
                </div>
                <div className="desc">
                  We will notify you once approved and shipped.
                </div>
              </div>
              <div className="primary_btn">
                <Link className="btn btn-primary" to="/app/my-orders">
                  Return to order Page
                </Link>
              </div>
              <img className="hippa_img" src={hipaa} alt="Hippa" />
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default Confirmation;
