import React from "react";
// import { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

import { planValidationSchema } from "../../../../../validations/validation";
import useAlertUtils from "../../../../../utils/common.utils";
import { SubmitData } from "../../../../../services/apiService";
import { useParams } from "react-router-dom";

// Define the intervals as static constants for validation purposes

const CreateCatalog = ({ modal, product, catalogId, plans }) => {
  const { alertSuccess, alertError } = useAlertUtils();
  const params = useParams();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await SubmitData("/api/catalog/plans", values);
      if (response?.success) {
        alertSuccess(response?.message);
      } else {
        alertError(response?.message);
      }

      modal(false);
      plans()
    } catch (error) {
      alert("Issue while creating product catalog");
    } finally {
      // Reset form fields after submission
      resetForm({
        productName: "",
        name: "",
        description: "",
        currentPrice: "",
        price: "",
        interval_unit: "",
        interval_count: "",
        total_cycles: "",
      });
    }
  };

  return (
    <div>
      <div className="main_content product_details">
        <div className="container">
          <div className="my-2 mx-1">
            <div className="col-lg-12 part left_part">
              <Formik
                initialValues={{
                  productName: "Sample Product",
                  name: "",
                  description: "",
                  currentPrice: 100, // Example current price
                  price: "",
                  interval_unit: "",
                  interval_count: "",
                  productId: params.productId,
                  total_cycles: "",
                }}
                validationSchema={planValidationSchema}
                onSubmit={handleSubmit}
              >
                {() => (
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form_field_wrapper">
                          <label>Product Name</label>
                          <Field
                            type="text"
                            placeholder="Product Name"
                            value={product?.productName}
                            className="form-control inp_text"
                            name="productName"
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form_field_wrapper">
                          <label>Strength</label>
                          <Field
                            as="select"
                            name="Strength"
                            className="form-control inp_text"
                          >
                            <option value="">Select Strength</option>
                            {product?.variants?.map((variant) => (
                              <option value={variant?.id}>
                                {variant?.strength}
                              </option>
                            ))}
                          </Field>

                          <ErrorMessage
                            name="strength"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form_field_wrapper">
                          <label>Plan Name</label>
                          <Field
                            type="text"
                            placeholder="Plan Name"
                            className="form-control inp_text"
                            name="name"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form_field_wrapper">
                          <label>Plan Description</label>
                          <Field
                            as="textarea"
                            className="form-control line_wrap"
                            id="description"
                            name="description"
                            rows="3"
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="error"
                          />
                        </div>

                        <Field
                          type="hidden"
                          id="productId"
                          value={product?.productId}
                          name="productId"
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="form_field_wrapper">
                          <label>Product Price</label>
                          <Field
                            type="text"
                            placeholder="Current Price"
                            className="form-control inp_text"
                            name="currentPrice"
                            readOnly
                          />
                          <ErrorMessage
                            name="currentPrice"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form_field_wrapper">
                          <label>Plan Price</label>
                          <Field
                            type="text"
                            placeholder="Plan Price"
                            className="form-control inp_text"
                            name="price"
                          />
                          <ErrorMessage
                            name="price"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form_field_wrapper">
                          <label>Interval Unit</label>
                          <Field
                            as="select"
                            aria-label="Default form-control select example"
                            name="interval_unit"
                          >
                            <option value="">Select Interval</option>
                            <option value="DAY">DAY</option>
                            <option value="WEEK">WEEK</option>
                            <option value="MONTH">MONTH</option>
                            <option value="YEAR">YEAR</option>
                          </Field>
                          <ErrorMessage
                            name="interval_unit"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form_field_wrapper">
                          <label>Interval count</label>
                          <Field
                            type="text"
                            placeholder="Interval Count"
                            className="form-control inp_text"
                            name="interval_count"
                          />
                          <ErrorMessage
                            name="interval_count"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form_field_wrapper">
                          <label>Total Cycles</label>
                          <Field
                            type="text"
                            placeholder="Total Cycles"
                            className="form-control inp_text"
                            name="total_cycles"
                          />
                          <ErrorMessage
                            name="total_cycles"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary btn_submit"
                    >
                      Submit
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCatalog;
