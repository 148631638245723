import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Button, Modal, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import Tooltip from "@mui/material/Tooltip";
import { HiSearch } from "react-icons/hi";
import useAlertUtils from "../../../../utils/common.utils";
// import { getCookie } from "../../../../utils/common.utils";
import { fetchData, deleteData } from "../../../../services/apiService";
import "./Catalog.scss";
import ReactPaginate from "react-paginate";

import { encryptData } from "../../../../services/encryptionDecription.service";
// import {
//   decryptedData,
//   makeEncryptionData,
// } from "../../../../services/encryptionDecription.service";
import Layout from "../../Layouts/layout";
import BrokenImage from "../../../../assets/img/Broken Img.jpg";

function Catalogs() {
  // const token = getCookie("token");
  // const navigate = useNavigate();

  // const [results, setResults] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 10;

  const [input, setInput] = useState("");
  // const [reportStatus, setReportStatus] = useState("");
  // const [message, setMessage] = useState();

  const { alertSuccess, alertError } = useAlertUtils();
  const [resultData, setResultData] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedCatalogId, setSelectedCatalogId] = useState(null);

  const fetchCatalogs = async () => {
    let results = await fetchData(
      `/api/catalog/all?page=${currentPage}&limit=${limit}&search=${btoa(
        encryptData(input)
      )}`
    );
    setResultData(results?.data?.data);
    setPageCount(results?.data?.totalPages);
  };

  useEffect(() => {
    fetchCatalogs();
  }, [currentPage, input]);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
  };

  // Search Functionality
  function searchFunc() {
    fetchCatalogs(); // Call fetchCatalogs to apply the search input
  }

  // const filterChange = (event) => {
  //   setInput("");
  //   setReportStatus(event.target.value);
  // };

  const handleDelete = async () => {
    try {
      await deleteData(`/api/catalog/delete/${selectedCatalogId}`, "DELETE"); // Replace with actual delete API call
      alertSuccess("Catalog deleted successfully");
      setOpen(false);
      fetchCatalogs(); // Refresh the list after deletion
    } catch (error) {
      alertError("Error deleting catalog");
    }
  };

  return (
    <>
      <Layout>
        <div className="main_content">
          <div className="container">
            <div className="main_block">
              <div className="top_bar">
                <div className="left_part">
                  <div className="main_title">All Catalogs</div>
                </div>

                <div className="right_part">
                  <div className="right_part_inner">
                    <div className="search_bar">
                      <div className="input-group">
                        <input
                          type="search"
                          value={input}
                          onChange={(e) => setInput(e.target.value)}
                          className="form-control"
                          placeholder="Search by Product"
                          aria-label="Search"
                        />
                        <button className="btn search-btn" onClick={searchFunc}>
                          <HiSearch size={20} />
                        </button>
                      </div>
                    </div>

                    <Link
                      to="/app/catalogs/add"
                      className="btn btn-primary add_kit"
                    >
                      Create Catalog
                    </Link>
                  </div>
                </div>
              </div>

              <div className="table_wrapper dashboard">
                <div className="table_inner">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>SNo.</TableCell>
                          <TableCell align="right">Product Image</TableCell>
                          <TableCell align="right">Product Name</TableCell>
                          <TableCell align="right">Description </TableCell>
                          <TableCell align="right">Price</TableCell>
                          <TableCell align="right">Plans</TableCell>
                          <TableCell align="right">Product Link</TableCell>
                          <TableCell align="right">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {resultData?.map((row) => (
                          <TableRow
                            key={row?.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="right">
                              <span className="mob_head">SNO.</span>
                              {row?.id}
                            </TableCell>

                            <TableCell align="right">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_PATH}/${row.productImage}`}
                                height={100}
                                width={100}
                                alt="product img"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = BrokenImage;
                                }}
                              />
                            </TableCell>

                            <TableCell align="right">
                              <span className="mob_head">Product Name</span>
                              {row?.productName}
                            </TableCell>
                            <TableCell align="right">
                              <span className="mob_head">Description</span>{" "}
                              {row?.description}
                            </TableCell>

                            <TableCell align="right">
                              <span className="mob_head">Price</span>
                              {row?.basePrice}
                            </TableCell>

                            <TableCell align="right">
                              <span className="mob_head">Plans</span>
                              <Link to={`/app/list-plans/${row?.id}`}>
                                View plans
                              </Link>
                            </TableCell>

                            <TableCell align="right">
                              <span className="mob_head">Product Link</span>
                              <Link
                                to={`${process.env.REACT_APP_API_URL}/register?procedure=store-consultation&productId=${row?.id}`}
                                target="_blank"
                              >
                                Link
                              </Link>
                            </TableCell>

                            <TableCell align="right">
                              <span className="mob_head">Actions</span>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4px",
                                }}
                              >
                                <Link
                                  to={`/app/catelogs/edit?productId=${row?.id}`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    style={{ marginRight: "4px" }}
                                  >
                                    <path
                                      d="M4 16.9032V20H8.07425C8.34627 20 8.60655 19.8892 8.79509 19.6931L20.3203 7.70684C20.6978 7.31424 20.6917 6.69174 20.3066 6.30662L17.7206 3.72057C17.3248 3.32483 16.6814 3.3309 16.2931 3.73404L4.27968 16.2096C4.10024 16.3959 4 16.6445 4 16.9032Z"
                                      stroke="black"
                                      stroke-linecap="round"
                                    />
                                    <path
                                      d="M15 5L19 9"
                                      stroke="black"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </Link>
                                <div
                                  onClick={() => {
                                    setSelectedCatalogId(row?.id);
                                    setOpen(true);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M6 6H18V19H6V6Z"
                                      stroke="#B0B0B0"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M9 6V4H15V6"
                                      stroke="#B0B0B0"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M9 11V16"
                                      stroke="#B0B0B0"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M12 11V16"
                                      stroke="#B0B0B0"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M15 11V16"
                                      stroke="#B0B0B0"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}

                        {resultData.length === 0 ? (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell colSpan={7}>
                              <div className="notice">
                                <div className="alert">
                                  <span className="alertText">
                                    No Records Found, add kit using the Add Kit
                                    button
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>

              <div className="bottom_bar">
                <div className="table_wrapper dashboard">
                  <div className="table_inner"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <div
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#fff",
              margin: "auto",
              width: "300px",
              marginTop: "150px",
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              Delete Catalog
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete this catalog? This action cannot
              be undone.
            </Typography>
            <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
              <Button onClick={handleDelete} variant="contained" color="error">
                Yes, Delete
              </Button>
              <Button onClick={() => setOpen(false)} variant="outlined">
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          marginPagesDisplayed={2}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
        />
      </Layout>
    </>
  );
}

export default Catalogs;
