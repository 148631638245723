import { createSlice } from '@reduxjs/toolkit'

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        value: {},
        type:"oneTime"
    },
    reducers: {
        addToCart: (state, action) => {
            state.value = action.payload
        },
        paymentType:(state, action) =>{
             state.type = action.payload
        },
        removeCart: (state) => {
            state.value = {}
        }

    }
})

export const { addToCart, removeCart, paymentType } = cartSlice.actions

export default cartSlice.reducer