import React from "react";
// import { useState } from 'react'
import logo from "../../assets/img/logo.svg";
import { NavLink } from "react-router-dom";
import "../PreLoginHeader/PreLoginHeader.scss";
import { useEffect } from "react";

const PreLoginHeader = () => {
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  // Header sticky
  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 100
      ? header?.classList.add("sticky")
      : header?.classList.remove("sticky");
  };

  return (
    <>
      <header className="header-section prelogin_header">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <NavLink className="logo" to="/">
              <img src={logo} alt="Pre Login Header" />
            </NavLink>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mb-2 mb-lg-0">
                {/* <li className="nav-item">
                  <NavLink className="nav-link" to="/home">Home</NavLink>
                </li>
               s
                <li className="nav-item">
                  <NavLink className="nav-link" to="/contact-us">Contact Us</NavLink>
                </li> */}

                <li className="nav-item">
                  <NavLink className="nav-link" to="/">
                    Login
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="/register">
                    Sign Up
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default PreLoginHeader;
